<template>
	<div>
		<div class="modal-header">
			<div class="modal-title">
				<span>{{ realName ? realName : '' }}({{ jobNumber ? jobNumber : '' }})</span>
			</div>
		</div>
		<!-- usePagination开启分页 -->
		<!-- loading绑定引入页面的蒙层加载变量 -->
		<q-container usePagination :loading="loading">
			<!-- 使用具名插槽 #toolbar -->
			<!-- #toolbar可替换成slot="toolbar" #井号只是语法糖 -->
			<template #toolbar>
				<a-row>
					<a-col :span="2">
						生效日期：
					</a-col>
					<a-col :span="6">
						<a-range-picker @change="onActiveDateChange" style="width: 95%" />
					</a-col>
					<a-col :span="4">
						<a-select
							show-search
							allowClear
							placeholder="请选择变动项目"
							option-filter-prop="children"
							style="width: 90%"
							v-model="changeItemCode"
							@change="refresh"
						>
							<a-select-option v-for="item in ChangeItems" :key="item.code">
								{{ item.name }}
							</a-select-option>
						</a-select>
					</a-col>
					<a-col :span="4">
						<a-select
							show-search
							allowClear
							placeholder="请选择变动原因"
							option-filter-prop="children"
							style="width: 90%"
							v-model="changeReasonCode"
							@change="refresh"
						>
							<a-select-option v-for="item in ChangeReasons" :key="item.itemDetailCode">
								{{ item.itemDetailName }}
							</a-select-option>
						</a-select>
					</a-col>
					<a-col :span="6" :offset="2">
						<a-input-search
							style="margin-top: 4px"
							name="filterText"
							:placeholder="l('SearchWithThreeDot')"
							@search="refresh"
							enterButton
							v-model="filterText"
						/>
					</a-col>
				</a-row>
			</template>
			<!-- 使用具名插槽slot="table" -->
			<!-- 插槽作用域的变量 slot-scope="scopeParam" -->
			<!-- 通过使用scopeParam.scroll.y 获取组件内部计算y值 -->

			<!-- **************行选择*************** -->
			<!-- customRow需要选择表格时添加属性和同名方法，方法不需要重新，在混入里面 -->
			<!-- 行选择默认单选，多选需要再data重写变量 isMultiple 并赋值true -->
			<template slot="table" slot-scope="scopeParam">
				<a-table
					ref="table"
					size="small"
					:pagination="false"
					:columns="columns"
					@change="sorterChange"
					:rowKey="(tableDatas) => tableDatas.id"
					:scroll="{ x: scroll_x, y: scopeParam.scroll.y }"
					:dataSource="tableData"
				>
				</a-table>
			</template>
			<!-- 使用具名插槽 #page -->
			<template #page>
				<!-- 分页组件只需复制以下代码 -->
				<!-- 处理逻辑和变量都在 app-component-base混入文件 -->
				<a-pagination
					v-model="pageNumber"
					:total="totalItems"
					showSizeChanger
					size="small"
					:defaultPageSize="request.maxResultCount"
					:pageSizeOptions="pageSizeOptions"
					:showTotal="() => showTotal"
					@change="onPageChange"
					@showSizeChange="showPageSizeChange"
				/>
			</template>
		</q-container>
	</div>
</template>

<script>
import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
import { ChangeRecordServiceProxy } from '../../../../shared/service-proxies';
import Dic from '../../../../shared/utils/dic';
import moment from 'moment';

export default {
	name: 'salary-change-detail-view',
	mixins: [ModalComponentBase],
	data() {
		return {
			empId: undefined,
			columns: [
				{
					title: '生效日期',
					dataIndex: 'effectiveDate',
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'effectiveDate' },
				},
				{
					title: '变更项目',
					dataIndex: 'changeItemName',
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'changeItemName' },
				},
				{
					title: '变更方式',
					dataIndex: 'changeWayName',
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'changeWayName' },
				},
				{
					title: '更改值',
					dataIndex: 'changeValue',
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'changeValue' },
				},
				{
					title: '原金额',
					dataIndex: 'originalAmount',
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'originalAmount' },
				},
				{
					title: '新金额',
					dataIndex: 'newAmount',
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'newAmount' },
				},
				{
					title: '变更原因',
					dataIndex: 'changeReasonName',
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'changeReasonName' },
				},
				{
					title: '变更人',
					dataIndex: 'creatorUserName',
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'creatorUserName' },
				},
			],
			tableData: [],
			realName: undefined,
			jobNumber: undefined,
			//变更原因
			changeReasonCode: undefined,
			ChangeReasons: [],
			//变更项
			changeItemCode: undefined,
			ChangeItems: [],

			//开始日期
			startDate: undefined,
			//结束日期
			endDate: undefined,
		};
	},
	created() {
		this.fullData();
		this._changeRecordServiceProxy = new ChangeRecordServiceProxy(this.$apiUrl, this.$api);
	},
	async mounted() {
		try {
			this.loading = true;
			this.ChangeReasons = await Dic.getInstance().getDicAsync('QYB_SL_ChangeReason');
			this.ChangeItems = await this._changeRecordServiceProxy.getSalaryChangeItemsData();
			if (this.empId) {
				this.getData();
			}
		} catch (e) {
		} finally {
			this.loading = false;
		}
	},
	methods: {
		getData() {
			this.loading = true;
			this._changeRecordServiceProxy
				.getPaged(
					this.empId,
					this.startDate,
					this.endDate,
					undefined,
					undefined,
					this.changeItemCode,
					this.changeReasonCode,
					this.filterText,
					this.request.sorting,
					this.request.maxResultCount,
					this.request.skipCount
				)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.tableData = res.items;
					this.tableData.map((item) => {
						item.effectiveDate = item.effectiveDate ? moment(item.effectiveDate).format('YYYY-MM-DD') : '';
					});
					this.totalItems = res.totalCount;
				});
		},
		//生效日期选择
		onActiveDateChange(e) {
			this.startDate = e[0];
			this.endDate = e[1];
			this.refresh();
		},
	},
};
</script>

<style scoped></style>
