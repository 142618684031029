<template>
	<a-config-provider :locale="zh_CN">
		<a-spin :spinning="loading">
			<!-- 标题 -->
			<div class="modal-header">
				<div class="modal-title">
					<span>{{ realName ? realName : '' }}({{ jobNumber ? jobNumber : '' }})</span>
				</div>
			</div>
			<div class="my-md">
				<a-row>
					<a-col :span="4">
						<q-label :label="l('是否发薪')"> </q-label>
					</a-col>
					<a-col :span="4" style="line-height: 35px">
						<a-switch v-model="entity.isPay" checked-children="是" un-checked-children="否" />
					</a-col>
					<a-col :span="3" offset="3">
						<q-label :label="l('五险一金')"> </q-label>
					</a-col>
					<a-col :span="2" style="line-height: 35px">
						<a-switch v-model="entity.isHaveSocial" checked-children="有" un-checked-children="无" />
					</a-col>
					<a-col :span="8">
						<a-select
							:disabled="!entity.isHaveSocial"
							show-search
							:placeholder="l('社保类别')"
							option-filter-prop="children"
							style="width: 90%"
							allowClear
							aria-required="true"
							v-model="entity.socialType"
						>
							<a-select-option v-for="item in socialTypeList" :key="item.id">
								{{ item.name }}
							</a-select-option>
						</a-select>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :required="empSocialSecurityBase.isHaveSocial" :label="l('社保基数')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-input-number
							v-model="empSocialSecurityBase.ssPaymentBase"
							:disabled="!entity.isHaveSocial"
							:min="0"
							style="width: 90%"
						/>
					</a-col>
					<a-col :span="4">
						<q-label :label="l('社保扣款日期')"> </q-label>
					</a-col>
					<a-col :span="8">
						<div>
							<a-date-picker
								v-model="empSocialSecurityBase.ssPayDate"
								:disabled="!entity.isHaveSocial"
								style="width: 90%"
							/>
						</div>
						<div class="base_tip">
							薪资计算中按扣款日期起生效
						</div>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('公积金基数')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-input-number
							v-model="empSocialSecurityBase.pfPaymentBase"
							:disabled="!entity.isHaveSocial"
							:min="0"
							style="width: 90%"
						/>
					</a-col>
					<a-col :span="4">
						<q-label :label="l('公积金扣款日期')"> </q-label>
					</a-col>
					<a-col :span="8">
						<div>
							<a-date-picker
								v-model="empSocialSecurityBase.pfPayDate"
								:disabled="!entity.isHaveSocial"
								style="width: 90%"
							/>
						</div>
						<div class="base_tip">
							薪资计算中按扣款日期起生效
						</div>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('转正前薪资')"> </q-label>
					</a-col>
					<a-col :span="5">
						<a-input-number v-model="entity.formalFrontSalary" :min="0" style="width: 100%" />
					</a-col>
					<a-col :span="3">
						<q-label :label="l('转正后薪资')"> </q-label>
					</a-col>
					<a-col :span="5">
						<a-input-number v-model="entity.formalAfterSalary" :min="0" style="width: 100%" />
					</a-col>
					<a-col :span="3">
						<q-label :label="l('调薪幅度')"> </q-label>
					</a-col>
					<a-col :span="4">
						<a-input-number v-model="entity.changeSalaryProp" :min="0" style="width: 100%" />
					</a-col>
				</a-row>
				<a-row style="margin-bottom: 10px">
					<a-col span="22" offset="2">
						<a-alert
							message="以下为薪资固定项数据，如需保留异动记录，请到薪资异动维护页面操作"
							type="warning"
							show-icon
						/>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('标准月薪')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-input-number
							:min="0"
							style="width: 100%"
							placeholder="标准月薪"
							v-model="fixedInfo.monthlyPay"
						/>
					</a-col>
					<a-col :span="4">
						<q-label :label="l('底薪')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-input-number :min="0" style="width: 100%" placeholder="底薪" v-model="fixedInfo.basePay" />
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('主管加给')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-input-number
							:min="0"
							style="width: 100%"
							placeholder="主管加给"
							v-model="fixedInfo.managerAdd"
						/>
					</a-col>
					<a-col :span="4">
						<q-label :label="l('岗位津贴')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-input-number
							:min="0"
							style="width: 100%"
							placeholder="岗位津贴"
							v-model="fixedInfo.psotAllowance"
						/>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('技术津贴')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-input-number
							:min="0"
							style="width: 100%"
							placeholder="技术津贴"
							v-model="fixedInfo.skillAllowance"
						/>
					</a-col>
					<a-col :span="4">
						<q-label :label="l('全勤津贴')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-input-number
							:min="0"
							style="width: 100%"
							placeholder="全勤津贴"
							v-model="fixedInfo.fullAllBonus"
						/>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('通讯补贴')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-input-number
							:min="0"
							style="width: 100%"
							placeholder="通讯补贴"
							v-model="fixedInfo.communicationSubsidy"
						/>
					</a-col>
					<a-col :span="4">
						<q-label :label="l('预付加班工资')"> </q-label>
					</a-col>
					<a-col :span="8">
						<div>
							<a-input-number
								:min="0"
								style="width: 100%"
								placeholder="预付加班工资"
								v-model="fixedInfo.prepaidOtPay"
							/>
						</div>
						<div class="base_tip">
							仅责任制
						</div>
					</a-col>
				</a-row>
			</div>
			<!-- 按钮 -->
			<div class="modal-footer">
				<a-button @click="close" type="button">
					<a-icon type="close-circle" />
					{{ l('Cancel') }}
				</a-button>
				<a-button :type="'primary'" @click="save">
					<a-icon type="save" />
					{{ l('Save') }}
				</a-button>
			</div>
		</a-spin>
	</a-config-provider>
</template>

<script>
import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
import {
	BaseInfoEditDto,
	BaseInfoServiceProxy,
	CreateOrUpdateBaseInfoInput,
	EmpSocialSecurityBaseEditDto,
	FixedInfoEditDto,
	SocialSecurityCategoryServiceProxy,
} from '../../../../shared/service-proxies';

export default {
	name: 'edit-base-info',
	mixins: [ModalComponentBase],
	data() {
		return {
			zh_CN,
			id: undefined,
			entity: new BaseInfoEditDto(),
			socialTypeList: [],
			realName: undefined,
			jobNumber: undefined,
			empSocialSecurityBase: new EmpSocialSecurityBaseEditDto(),
			fixedInfo: new FixedInfoEditDto(),
			empId: undefined,
		};
	},
	created() {
		this.fullData();
		this._socialSecurityCategoryServiceProxy = new SocialSecurityCategoryServiceProxy(this.$apiUrl, this.$api);
		this._baseInfoServiceProxy = new BaseInfoServiceProxy(this.$apiUrl, this.$api);
	},
	async mounted() {
		this.socialTypeList = await this.getsocialTypeList();
		if (this.id) {
			this.getData();
		}
	},
	methods: {
		getData() {
			this.loading = true;
			this._baseInfoServiceProxy
				.getForEdit(this.empId)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.entity = res.baseInfo;
					this.empSocialSecurityBase = res.empSocialSecurityBase;
					this.fixedInfo = res.fixedInfo;
				});
		},
		getsocialTypeList() {
			return new Promise((resolve) => {
				this.loading = true;
				this._socialSecurityCategoryServiceProxy
					.getAllList()
					.finally(() => {
						this.loading = false;
					})
					.then((res) => {
						resolve(res);
					});
			});
		},
		save() {
			if (this.entity.isHaveSocial && !this.entity.socialType) {
				return abp.message.warn('请选择社保类型');
			}
			let input = new CreateOrUpdateBaseInfoInput();
			input.baseInfo = this.entity;
			input.fixedInfo = this.fixedInfo;
			input.empSocialSecurityBase = this.empSocialSecurityBase;
			this.loading = true;
			this._baseInfoServiceProxy
				.createOrUpdate(input)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.$notification['success']({
						message: this.l('SavedSuccessfully'),
					});
					this.success(true);
				});
		},
	},
};
</script>

<style scoped>
.base_tip {
	color: #797979;
	font-size: 10px;
}
</style>
